<template>
  <div class="vc-window">
    <div class="vc-background"></div>
    <router-link to="/" class="mt-2 pt-4 pb-3">
      <b-img-lazy fluid :src="require('@/assets/img/logo-brown.svg')" alt="" width="100"/>
    </router-link>
    <div class="vc-container">
      <p class="vc-brand">
        <span v-for="(product, i) in details" :key="i" :class="{'opacity-0': index != i}">{{ product.brand }}</span>
      </p>
      <h1 class="vc-item">
        <span v-for="(product, i) in details" :key="i" :class="{'opacity-0': index != i}">{{ product.product_name }}</span>
      </h1>
      <div class="vc-img">
        <router-link :to="`/product${details[index]?.productUrl.split(/\.com\/product|\?/)[1]}`" target="_blank">
          <b-img-lazy fluid
            v-for="(product, i) in details" :key="i"
            :src="product.image_url_hd"
            :alt="product.name"
            class="rounded-circle"
            :class="{'opacity-0': index != i}"
            width="200" />
        </router-link>
      </div>
      <p class="vc-dearest">Dearest,</p>
      <p class="vc-recipient">{{ voice.recipient_name ?? voice.email }}</p>
      <p class="vc-message">
        {{ voice.recipient_message ?? 'I hope this message can light up your day, and this jewelry shines as much as you do.' }}
      </p>
      <template v-if="voice.full_url">
        <div class="vc-controls">
          <font-awesome v-if="!player.playing" icon="play" class="cursor-pointer" @click="togglePlay" />
          <font-awesome v-else icon="pause" class="cursor-pointer" @click="togglePlay" />
          <fawesome-pro v-if="!player.muted" icon="volume" class="cursor-pointer" @click="toggleAudio" />
          <fawesome-pro v-else icon="volume-mute" class="cursor-pointer" @click="toggleAudio" />
          <span>{{ toTime(player.progress) }}</span>
          <div class="vc-slider-bg">
            <div class="vc-slider" :style="{width: `${player.progress / player.duration * 100}%`}"></div>
          </div>
          <span>{{ toTime(player.duration) }}</span>
        </div>
        <audio
          id="voice"
          ref="voice"
          :src="voice.full_url"
          type="audio/mp3"
          @loadedmetadata="player.duration = $event.target.duration"
          @timeupdate="player.progress = $event.target.currentTime"
        >
          Your browser does not support the audio element.
        </audio>
      </template>
      <p class="vc-sender">From: <span>{{ voice.sender_name ?? voice.email }}</span></p>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Voice",
  data() {
    return {
      voice: [],
      details: [],
      index: 0,
      player: {
        play: false,
        playing: false,
        muted: false,
        duration: 0,
        progress: 0,
      }
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  mounted() {
    this.getVoiceData(this.$route.params.slug);
  },
  methods: {
    togglePlay() {
      this.player.play = this.player.playing = !this.player.playing
      if (this.player.play)
        this.$refs.voice.play()
      else this.$refs.voice.pause()
    },
    toggleAudio() {
      this.$refs.voice.muted = this.player.muted = !this.player.muted
    },
    setVoiceSample(gender) {
      this.voice = [];
      let temp;
      switch (gender) {
        case "f":
          temp = {
            email: "Our Beloved Customer",
            full_url:
              "https://cdn.whizliz.com/campaign/HAPPY%20VDAY%20-%20FEMALE%201.mp3",
            details: this.details,
          };
          break;
        case "m":
          temp = {
            email: "Our Beloved Customer",
            full_url:
              "https://cdn.whizliz.com/campaign/HAPPY%20VDAY%20-%20MALE%201.mp3",
            details: this.details,
          };
          break;
      }
      this.voice.push(temp);
      this.voice = this.voice[0];
      this.eventHandlers(this.voice, true);
    },
    switchProduct() {
      const { length } = this.details
      setTimeout(() => {
        if (this.index + 1 == length) this.index = 0
        else this.index += 1
        this.switchProduct()
      }, 5000)
    },
    toTime(value) {
      return `${(value / 60).toFixed(0)}:${((value) => value < 10 ? "0" + value : value)(value.toFixed(0) % 60)}`
    },
    async getVoiceData(id) {
      // if (!id) {
      //   let detailTemp = {
      //     image_url_hd: require("@/assets/img/whiz-logo.png"),
      //     brand: "Happy Valentine",
      //     product_name: "From Whizliz",
      //     productNameFull: "Whizliz.com",
      //   };
      //   this.details.push(detailTemp);
      //   this.details = this.details[0];
      //   this.setVoiceSample("f");
      // } else {
      try {
        const res = await axios.get(process.env.VUE_APP_API_URL + "users/order/detail_order_voice/" + id, {
          headers: {
            api_key: process.env.VUE_APP_API_KEY,
          },
        })
        if (res.status === 200) {
          this.voice = res.data.data
          this.details = this.voice.details
          this.switchProduct()
        } else {
          this.$helpers.toaster.make(res.data.message, 'danger')
          this.$router.push('/')
        }
      } catch (e) {
        if (e.response) this.$helpers.toaster.make('Something went wrong', 'danger')
        console.error(e);
        this.$router.push('/')
      }
      // }
    },
  },
  watch: {
    "player.progress"(newVal) {
      if (newVal >= this.player.duration)
        this.player.play = this.player.playing = false 
    }
  }
};
</script>
<style scoped>
.vc-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #9d6846;
  max-width: 600px;
  margin: auto;
  padding: 0 15px;
}
.vc-container {
  margin-top: 1.5rem;
  width: 100%;
}
.vc-background {
  position: fixed;
  background: url('../../assets/img/BG-LANDING-PAGE-VOICE-MESSAGE-PORTRAIT.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  inset: 0;
}
@media (min-width: 768px) {
  .vc-window {
    height: 100vh;
  }
  .vc-container {
    margin: auto 0;
  }
  .vc-background {
    background: url('../../assets/img/BG-LANDING-PAGE-VOICE-MESSAGE.jpg');
  }
}
.vc-brand {
  font-size: 10pt;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  position: relative;
  display: -webkit-box;
  text-overflow: ellipsis;
  box-orient: vertical;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: relative;
  height: 20px;
}
.vc-brand > span {
  position: absolute;
  inset: 0;
}
.vc-item {
  font-family: 'Roboto';
  font-size: 18pt;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  display: -webkit-box;
  text-overflow: ellipsis;
  box-orient: vertical;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: relative;
  height: 28.8px;
}
.vc-item > span {
  position: absolute;
  inset: 0;
}
.vc-img {
  padding: 1.5rem;
  height: calc(200px + 3rem);
  position: relative;
}
.vc-img a {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  width: 100%;
}
.vc-img img {
  position: absolute;
}
.vc-dearest {
  font-family: 'Playfair Italic';
  margin: 0;
}
.vc-recipient {
  font-family: 'Roboto Medium';
}
.vc-message {
  font-size: 11pt;
  margin-bottom: 1.5rem;
  white-space: pre-line;
}
.vc-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}
.vc-slider-bg {
  position: relative;
  background: #9d6846;
  height: 3px;
  flex-grow: 1;
}
.vc-slider {
  pointer-events: none;
  width: 0%;
  max-width: 100%;
  height: 3px;
  background: #e5b593;
  position: absolute;
  inset: 0;
}
.vc-sender {
  font-size: 10pt;
}
.vc-sender > span {
  font-family: 'Roboto Bold';
}
/* .vc-slider::after {
  content: "";
  position: absolute;
  top: -2px;
  right: -4px;
  background: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0;
  transition: .2s all linear;
}
.vc-slider-bg:hover .vc-slider::after {
  opacity: 1;
} */
</style>
